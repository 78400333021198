.color-ev {
    background-color: rgb(12, 152, 152);
}
.full-page {
    display: flex;
    justify-content: center;
    align-items: center;
}
.full-page:after {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    /* background-color: rgba(0, 0, 0, 0.4); */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0.1));
}
.section-image {
    background-size: cover;
    background-position: center center;
    position: relative;
    width: 100%;
}
.section-image:after {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    /* background-color: rgba(0, 0, 0, 0.7); */
}
.section-image .description, .section-image .info-area .icon:not(.icon-circle) {
    color: rgba(255, 255, 255, 0.8);
}
.full-page.register-page .info-horizontal .description {
    overflow: hidden;
}
.full-page .full-page-background {
position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
}

.full-page>.content,
.full-page>.footer {
    position: relative;
    z-index: 4;
}

.full-page.section-image {
    position: initial;
}

.full-page .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.full-page>.content {
    padding-top: 50px;
    width: 100%;
}

.card-calendar .content {
    padding: 0 !important;
}

.card-signup .content {
    padding: 0px 30px;
}

.rtl-active .navbar>.container-fluid .navbar-brand {
    margin-right: 10px;
}

.rtl-active .navbar>.container-fluid .navbar-brand {
    margin-right: 10px;
}

.full-page .footer .container {
    color: #FFFFFF;
}

.login-page .card-login .logo-container {
    width: 65px;
    margin: 0 auto;
    margin-bottom: 55px;
}
.login-page .card-login.card-plain .input-group.no-border .form-control,
.login-page .card-login.card-plain .input-group.no-border .input-group-prepend .input-group-text {
    color: #FFFFFF;
}
.login-page .card-login.card-plain .form-control::-webkit-input-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}

.tellemHome1 {
  background-image: url("/assets/tellem/v2/orange_top.webp");
}
.tellemHome2{
  background-image: url("/assets/tellem/v2/restaurant_1.webp");
}
.tellemHome3{
  background-image: url("/assets/tellem/v2/orange_bg_1.webp");
}
.tellemHome4{
  background-image: url("/assets/tellem/v2/white_bg_1.webp");
}
.tellemHome5{
  background-image: url("/assets/tellem/v2/hotel_1.webp");
}
.tellemHome6{
  background-image: url("/assets/tellem/v2/orange_bg_2.webp");
}

.tellemHome7{
  background-image: url("/assets/tellem/v2/white_bg_2.webp");
}
.tellemHome8{
  background-image: url("/assets/tellem/v2/bg16.webp");
}
.tellemHome9{
  background-image: url("/assets/tellem/v2/orange_bg_3.webp");
}
.tellemHome10{
  background-image: url("/assets/tellem/v2/about-team.webp");
}
.tellem-home-title{
    font-size: $font-size-extra-large;
}
.tellem-home-tag-line{
    font-size: $font-size-extra-large;
    line-height: 1.3em;
    &.blue{
        color: $color-dark-blue;
    }
}
.tellem-home-byline{
    font-size: $font-size-medium;
}
.tellem-home-header{
    font-size: $font-size-regular;
    color: $color-orange;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 20px 0px;
    &.left{
        text-align: left;
    }
    &.white{
        color: $color-white;
    }
}
.tellem-home-header-byline{
    font-size: $font-size-large;
    color: $color-black;
    text-align: center;
    margin: 0 auto;
    &.left{
        text-align: left;
    }
    &.white{
        color:$color-white;
    }
}
.tellem-home-subHeader{
    font-size: $font-size-medium;
    font-weight: bold;
    color: $color-black;
    &.white{
        color: $color-white;
    }
}
.tellem-home-content{
    font-size: $font-size-regular;
    color: $color-black;
    &.padding{
        padding: 10px 0px;
    }
    &.white{
        color: $color-white;
    }
}
.tellem-home-btn{
    display:flex;
    align-items: center;
    div{
    //    float:left;
    }
    .tellem-home-btn-pricing{
        font-size: $font-size-extra-small;
        text-align: center;
    }
}

.container--big {
    height: 175vh
}

.container--full {
    height: 100vh
}

.container--small {
    height: 40vh;
}
.container--60 {
    height: 70vh;
}
.container--70 {
    height: 70vh;
}
.container--80 {
    height: 80vh;
}
.container--90 {
    height: 90vh;
}
.container--120 {
    height: 120vh;
}
.container--medium {
    height: 60vh
}

.containerHome{
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 15px;
    position: relative;
    width: 100%;
    .tellem-content-full{
        width: 100%;
        .phone{
            height: 70%;
        }
    }
    .about{
        border-radius: 100%;
        display: flex;
        flex: 0 0 120px;
        height: 120px;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 120px;
    }
    .tellem-about-profile{
        color: $color-white;
        display: flex;
        align-items: center;
        span{
            padding-left: 7px;
            font-size: $font-size-regular;
        }
    }
    .tellem-home-orange-container{
        width: 100%;
        padding: 30px 35px;
        margin-top:-230px;
        .tellem-home-header{
            color: $color-white;
        }
        .laptop{
            width: 50%;
            float: left;
        }
        .icon{
            width: 40px;
            float: left;
            padding: 5px;
        }
        .tellem-home-orange-icons{
            color: $color-white;
            display: flex;
            align-items: center;
            span{
                font-size: $font-size-small;
            }
        }

    }
    .contentHome {
        background: rgba(0, 0, 0, .0);
        border-radius: 8px;
        color: #fff;
        padding: 30px 35px;
    }

    .noContentHome {
        background: rgba(0, 0, 0, .0);
        border-radius: 8px;
        color: #fff;
        margin: 0 auto;
        padding: 30px 35px;
    }
}
.contentHomeWelcome {
    margin-top: 50px;
}
.tellem-full-container{
    margin-top: 0px;
}
.tellem-home-laptop-left{
    float: left;
}

// .tellem-home-phone:after{
//     content: ' ';
//     border-bottom-right-radius: 175px;
//     width: 10%;
//     position: absolute;
//     border-bottom: 2px solid red;
//     right: 475px;
//     bottom: 58px;
//     height: 150px;
//     border-bottom-style: dashed
// }

// .tellem-home-business:before{
//   content: ' ';
//   width: 20%;
//   border-top: 2px solid red;
//   height: 135px;
//   position: absolute;
//   top: -60px;
//   left: 498px;
//   border-top-left-radius: 150px;
//   border-top-style: dashed;
// }
// MOBILE SASS

.tellem-home-mobile-header{
    font-size: $font-size-regular;
    color: $color-orange;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 20px 0px;
    &.left{
        text-align: left;
    }
    &.white{
        color: $color-white;
    }
}
.tellem-home-mobile-header-byline{
    font-size: $font-size-large;
    color: $color-black;
    text-align: center;
    margin: 0 auto;
    &.left{
        text-align: left;
    }
    &.white{
        color:$color-white;
    }
}
.tellem-home-mobile-title{
    font-size: $font-size-super-large;
}