/*!

 =========================================================
 * Tellem v1.0
 =========================================================

 * Product Page: https://www.mytellem.com
 * Copyright 2023 Tellem https://www.mytellem.com

 * Designed by marvin.sugirin.net

 =========================================================

 */

@import 'tellemv2/variables';
@import 'tellemv2/homepage';
@import 'tellemv2/footer';
@import 'tellemv2/button';
@import 'tellemv2/links';
@import 'tellemv2/login';
@import 'tellemv2/cards';
@import 'tellemv2/navbar';
@import 'tellemv2/misc';