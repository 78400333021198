.btn-tellem-blue{
	background-color: $color-dark-blue;
	color: $color-white;
}
.btn-tellem-lorange{
	background-color: $color-light-orange;
	color: $color-orange;
}

.btn-radius-large{
	border-radius: $border-radius-extreme;
}

.branding-product-preview{
	text-decoration: underline !important;
}