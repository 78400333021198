
.modal .modal-login .card-login .logo-container {
    width: 65px;
    margin-bottom: 38px;
    margin-top: 27px;
}

.login-page .card-login {
    border-radius: 0.25rem;
    padding-bottom: 0.7rem;
}

.login-page .card-login .btn-wd {
    min-width: 180px;
}

.login-page .card-login .logo-container {
    width: 65px;
    margin: 0 auto;
    margin-bottom: 55px;
}

.login-page .card-login .logo-container img {
    width: 100%;
}

.login-page .card-login .input-group:last-child {
    margin-bottom: 40px;
}

.login-page .card-login.card-plain .form-control::-moz-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}

.login-page .card-login.card-plain .form-control:-moz-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}

.login-page .card-login.card-plain .form-control::-webkit-input-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}

.login-page .card-login.card-plain .form-control:-ms-input-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}

.login-page .card-login.card-plain .form-control {
    border-color: rgba(255, 255, 255, 0.5);
    color: #FFFFFF;
}

.login-page .card-login.card-plain .form-control:focus {
    border-color: #FFFFFF;
    background-color: transparent;
    color: #FFFFFF;
}

.login-page .card-login.card-plain .has-success:after,
.login-page .card-login.card-plain .has-danger:after {
    color: #FFFFFF;
}

.login-page .card-login.card-plain .has-danger .form-control {
    background-color: transparent;
}

.login-page .card-login.card-plain .input-group-prepend .input-group-text,
.login-page .card-login.card-plain .input-group-append .input-group-text {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.5);
    color: #FFFFFF;
}

.login-page .card-login.card-plain .input-group-focus .input-group-prepend .input-group-text,
.login-page .card-login.card-plain .input-group-focus .input-group-append .input-group-text {
    background-color: transparent;
    border-color: #FFFFFF;
    color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border .form-control,
.login-page .card-login.card-plain .input-group.no-border .form-control {
    background-color: rgba(255, 255, 255, 0.1);
    color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border .form-control:focus,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:focus,
.login-page .card-login.card-plain .input-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-prepend .input-group-text,
.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-append .input-group-text,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-prepend .input-group-text,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-append .input-group-text {
    background-color: rgba(255, 255, 255, 0.1);
}

.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-prepend .input-group-text:focus,
.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-prepend .input-group-text:active,
.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-prepend .input-group-text:active,
.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-append .input-group-text:focus,
.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-append .input-group-text:active,
.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-append .input-group-text:active,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-prepend .input-group-text:focus,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-prepend .input-group-text:active,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-prepend .input-group-text:active,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-append .input-group-text:focus,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-append .input-group-text:active,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-append .input-group-text:active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border .form-control:focus+.input-group-prepend .input-group-text,
.login-page .card-login.card-plain .form-group.no-border .form-control:focus+.input-group-append .input-group-text,
.login-page .card-login.card-plain .input-group.no-border .form-control:focus+.input-group-prepend .input-group-text,
.login-page .card-login.card-plain .input-group.no-border .form-control:focus+.input-group-append .input-group-text {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border .input-group-prepend .input-group-text,
.login-page .card-login.card-plain .form-group.no-border .input-group-append .input-group-text,
.login-page .card-login.card-plain .input-group.no-border .input-group-prepend .input-group-text,
.login-page .card-login.card-plain .input-group.no-border .input-group-append .input-group-text {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border.input-group-focus .input-group-prepend .input-group-text,
.login-page .card-login.card-plain .form-group.no-border.input-group-focus .input-group-append .input-group-text,
.login-page .card-login.card-plain .input-group.no-border.input-group-focus .input-group-prepend .input-group-text,
.login-page .card-login.card-plain .input-group.no-border.input-group-focus .input-group-append .input-group-text {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
}

.login-page .card-login.card-plain .input-group-addon,
.login-page .card-login.card-plain .form-group.no-border .input-group-addon,
.login-page .card-login.card-plain .input-group.no-border .input-group-addon {
    color: rgba(255, 255, 255, 0.8);
}

.login-page .link {
    font-size: 10px;
    color: #FFFFFF;
    text-decoration: none;
}