$color-dark-blue: #1b3758;
$color-white: #ffffff;
$color-light-orange: #fdf0eb;
$color-orange: #db4f44;
$color-black: #000000;


$padding-base-vertical:        .5rem !default;
$font-size-extra-small:              0.7em     !default;
$font-size-small:              0.8571em     !default;
$font-size-regular:            1em     !default;
$font-size-medium:             1.2571em     !default;
$font-size-large:             1.8571em     !default;
$font-size-super-large:        2.8571em     !default;
$font-size-extra-large:        4.0571em     !default;


$border:                        1px solid   !default;
$border-radius-extra-small:     0.125rem    !default;
$border-radius-small:           0.1875rem   !default;
$border-radius-large:           0.25rem     !default;
$border-radius-extreme:         0.875rem    !default;