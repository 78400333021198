.tellem-no-numbering{
	list-style: none;
}
.tellem-privacy ol > li {
	font-weight: bold;
}

.tellem-privacy ol > li > ol > li {
  font-weight: normal;
}

.form-validation-error{
	font-weight: bold;
	font-size: 1em !important;
	color: #FF0000 !important;
}

.modalGroupName{
	font-weight: bold;
}

.tellemCompanyAuthor{
	font-size: 0.8em;
	color: #BBBBBB;
}